@import "./loading.css";
@import "@/theme/dist/default.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .heading-3 {
    @apply text-[40px] leading-[48px] font-bold;
  }
  .heading-4 {
    @apply text-[30px] leading-[38px] font-bold;
  }
  .heading-5 {
    @apply text-[28px] leading-[40px] font-semibold;
  }
  .heading-6 {
    @apply text-[24px] leading-[30px] font-semibold;
  }

  .body-large {
    @apply text-[18px] leading-[26px] font-semibold;
  }
  .body-medium {
    @apply text-[16px] leading-[24px] font-normal;
  }
  .body-small {
    @apply text-[14px] leading-[22px] font-normal;
  }
  .body-extra-small {
    @apply text-[12px] leading-[20px] font-normal;
  }
  .text-gift-message {
    @apply text-[32px] font-['Marck_Script'] font-normal tracking-[-0.704px];
  }
}

.react-grid-item.react-grid-placeholder {
  @apply bg-primary bg-opacity-50 rounded-md;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
