:root {
  --sidebar-background: 0 0% 100%;
  --sidebar-foreground: 240 5.3% 26.1%;
  --sidebar-primary: 240 5.9% 10%;
  --sidebar-primary-foreground: 0 0% 98%;
  --sidebar-accent: 240 4.8% 95.9%;
  --sidebar-accent-foreground: 240 5.9% 10%;
  --sidebar-border: 220 13% 91%;
  --sidebar-ring: 217.2 91.2% 59.8%;

  --white-dark: 219.13 40.35% 11.18%;
  --dark: 219.13 40.35% 11.18%;

  --background: 220 23.08% 97.45%;
  --foreground: 208 16% 60%;

  --background-secondary: 0 0% 100%;
  --background-secondary-foreground: 208 13% 45%;

  --muted: 210 40% 96.1%;
  --muted-foreground: 215.4 16.3% 46.9%;

  --popover: 0 0% 100%;
  --popover-foreground: 222.2 47.4% 11.2%;

  --border: 212.73 20.75% 89.61%;
  --input: 214.3 31.8% 91.4%;

  --card: 0 0% 100%;
  --card-foreground: 222.2 47.4% 11.2%;

  --primary: 198 82% 47%;
  --primary-foreground: 0 0% 100%;

  --secondary: 220 14% 96%;
  --secondary-foreground: 219 40% 11%;

  --accent: 210 40% 96.1%;
  --accent-foreground: 222.2 47.4% 11.2%;

  --destructive: 0 100% 50%;
  --destructive-foreground: 210 40% 98%;

  --ring: 215 20.2% 65.1%;

  --radius: 0.5rem;
  --error: 0 88% 57%;

  --toastify-color-error: #f23030;

  /* font size */
  --text-sm: 14px;
  --text-sm-leading: 22px;
  --text-base: 16px;
  --text-base-leading: 24px;
  --text-md: 16px;
  --text-md-leading: 24px;
  --text-lg: 18px;
  --text-lg-leading: 26px;
  --text-xl: 24px;
  --text-xl-leading: 30px;
  --text-2xl: 40px;
  --text-2xl-leading: 48px;

  /*  spacing */
  --spacing-extra-sm: 8px;
  --spacing-sm: 10px;
  --spacing-base: 12px;
  --spacing-md: 14px;
  --spacing-lg: 24px;
  --spacing-xl: 32px;

  /* button size */
  --btn-icon: 48px;
  --btn-sm: 40px;
  --btn-base: 44px;
  --btn-md: 44px;
  --btn-lg: 50px;
  --btn-xl: 52px;

  /* box color size */
  --box-color-sm: 22px;
  --box-color-base: 24px;
  --box-color-md: 28px;
  --box-color-lg: 32px;
  --box-color-xl: 40px;
}

.dark {
  --sidebar-background: 212.5 27.91% 16.86%;
  --sidebar-foreground: 240 4.8% 95.9%;
  --sidebar-primary: 224.3 76.3% 48%;
  --sidebar-primary-foreground: 0 0% 100%;
  --sidebar-accent: 240 3.7% 15.9%;
  --sidebar-accent-foreground: 240 4.8% 95.9%;
  --sidebar-border: 216.92 19.12% 26.67%;
  --sidebar-ring: 217.2 91.2% 59.8%;


  --white-dark: 0 0% 100%;
  --dark: 219.13 40.35% 11.18%;

  --background: 219 40% 11%;
  --foreground: 208 16% 60%;

  --background-secondary: 213 28% 17%;
  --background-secondary-foreground: 218 11% 65%;

  --muted: 223 47% 11%;
  --muted-foreground: 215.4 16.3% 56.9%;

  --accent: 216 34% 17%;
  --accent-foreground: 210 40% 98%;

  --popover: 213 28% 17%;
  --popover-foreground: 215 20.2% 65.1%;

  --border: 216 34% 17%;
  --input: 216 34% 17%;

  --card: 224 71% 4%;
  --card-foreground: 213 31% 91%;

  --primary: 198 82% 47%;
  --primary-foreground: 0 0% 100%;

  --secondary: 222.2 47.4% 11.2%;
  --secondary-foreground: 210 40% 98%;

  --destructive: 0 63% 31%;
  --destructive-foreground: 210 40% 98%;

  --ring: 216 34% 17%;
  --error: 0 88% 57%;

  --radius: 0.5rem;
}